import "./Login.css";
import Modal from "react-modal";
import Lottie from "lottie-react";
import { SHA256 } from "crypto-js";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { field, onlyNumber } from "../../Validation/Validation";
import { useSelectedCardContext } from "../../Context/Context";
import { getBaseURLTonavigatetoLogin } from "../../EnvoirnmentSetup/profiles";
import { getAPICall, patchAPICall, postAPICall } from "../../APIMethods/APIMethods";
import { LoginAutonavigateEnv, PortalUserRole, Url } from "../../EnvoirnmentSetup/APIs";
// import { getBaseURLForDevLogin, getBaseURLTonavigatetoLogin } from "../../EnvoirnmentSetup/profiles";

import Oops from "../../assets/Opps.json";
import eyeIcon from "../../assets/eyeIcon.svg";
import completed from "../../assets/Success.json";
import mobileImg from "../../assets/mobileImg.svg";
import logoImageUrl from "../../assets/quikHireLogo.png";
import hiddenEyeIcon from "../../assets/hiddenEyeIcon.svg";

const Login = () => {
  const navigate = useNavigate();
  const { setIsLoading, setWarning, setAlert } = useSelectedCardContext()
  // global.alert = setWarning

  const [successPopup, setSuccessPopup] = useState(false);
  const [hidePwd, setHidePwd] = useState({
    pwd: false,
    npwd: false,
    cnpwd: false,
  });
  const [forgotPassFlow, setForgotPassFlow] = useState(false)
  const [countryCodes, setCountryCodes] = useState([]);
  const [popupMsg, setPopupMsg] = useState("");
  const [forgotPwd, setForgotPwd] = useState(false);
  const [lgnDetails, setLgnDetails] = useState({
    cntryCode: "249",
    mno: "",
    pwd: "",
    npwd: "",
    cnpwd: "",
  });
  const [screen, setScreen] = useState(1);
  const [errMsg, setErrMsg] = useState({
    cntryCodeErr: "",
    mnoErr: "",
    pwdErr: "",
    npwdErr: "",
    cnpwdErr: "",
  });
  const [otp2, setOtp2] = useState("");
  const [msgAuditId, setMsgAuditId] = useState();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
    },
    overlay: {
      zIndex: 1
    },
  };

  useEffect(() => {
    let token = sessionStorage.getItem("token");
    if (!token && !sessionStorage.getItem('jobId')) {
      sessionStorage.clear()
    }
    if (token?.length > 0) {
      navigate("/Main/latestJob");
    } else {
      let fullURL = window.location.href;
      const url = new URL(fullURL);
      fullURL = fullURL.replace("#", "");
      // console.log('full url........', fullURL, url.searchParams.get("CCId"), url.searchParams.get("mobilenumber"));

      let userParamsCCId = url.searchParams.get("CCId") ?? "";
      let userParamsmobNumber = url.searchParams.get("mobilenumber") ?? "";

      if (!!userParamsCCId && !!userParamsmobNumber) {
        setLgnDetails({
          cntryCode: userParamsCCId,
          mno: userParamsmobNumber,
          pwd: "",
          npwd: "",
          cnpwd: "",
        });
        setScreen(1);
      } else {
        navigate("/login");
      }
    }
    getAPICall(Url.CountryCodes)
      .then((res) => setCountryCodes(res?.DATA ?? []))
      .catch(e=>console.log(e))
  }, []);

  useEffect(() => {
    if (otp2?.length > 3) {
      handleSetPwd()
    }
  }, [otp2])

  let mnoRegex = /^\d+$/;
  let lowerCase = /[a-z]/;
  let upperCase = /[A-Z]/;
  let oneDigitRegex = /[0-9]/;
  let oneSpclChar = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?/~`]).+$/;
  let passwordErrorMessages={
    isRequired: "Password is required.",
    minLength: "Minimum length should be 8.",
    maxLength: "Maximum length should be 16.",
    oneDigit: "Should accept at least one digit is required.",
    oneSpecial: "Should accept at least one special character is required.",
    oneLowerCase: "Should accept at least one lower case is required.",
    oneUpperCase: "Should accept at least one upper case is required.",
    confirmPassword: "Confirm password is required.",
    notMatch: "Password doesn't match.",
  }

  const handleLgnInputChange = (e, field) => {
    switch (field) {
      case "cntryCode":
        setLgnDetails({ ...lgnDetails, cntryCode: e?.target?.value });
        break;
      case "mno":
        if (e?.target?.value == "") {
          setErrMsg({ ...errMsg, mnoErr: "Mobile number is required." });
        } else if (!mnoRegex.test(e?.target?.value)) {
          setErrMsg({ ...errMsg, mnoErr: "Special characters and alphabets are not allowed." });
        } else if (e?.target?.value.length < 4) {
          setErrMsg({ ...errMsg, mnoErr: "Minimum length should be 4" });
        }
        // else if (e?.target?.value.length > 10) {
        //   setErrMsg({ ...errMsg, mnoErr: "Maximum length should be 10" })
        // }
        else {
          setErrMsg({ ...errMsg, mnoErr: "" });
        }
        if (e?.target?.value.length <= 10) {
          setLgnDetails({ ...lgnDetails, mno: onlyNumber('mobile number', e?.target?.value).fieldValue });
        }
        break;
      case "pwd":
        if (e?.target?.value == "") {
          setErrMsg({ ...errMsg, pwdErr: passwordErrorMessages.isRequired });
        } else if (!upperCase.test(e?.target?.value)) {
          setErrMsg({ ...errMsg, pwdErr: passwordErrorMessages.oneUpperCase });
        } else if (!lowerCase.test(e?.target?.value)) {
          setErrMsg({ ...errMsg, pwdErr: passwordErrorMessages.oneLowerCase });
        } else if (!oneDigitRegex.test(e?.target?.value)) {
          setErrMsg({ ...errMsg, pwdErr: passwordErrorMessages.oneDigit });
        } else if (!oneSpclChar.test(e?.target?.value)) {
          setErrMsg({ ...errMsg, pwdErr: passwordErrorMessages.oneSpecial });
        } else if (e?.target?.value.length < 8) {
          setErrMsg({ ...errMsg, pwdErr: passwordErrorMessages.minLength });
        } else if (e?.target?.value.length > 16) {
          setErrMsg({ ...errMsg, pwdErr: passwordErrorMessages.maxLength });
        } else {
          setErrMsg({ ...errMsg, pwdErr: "" });
        }
        setLgnDetails({ ...lgnDetails, pwd: e?.target?.value });
        break;
      case "npwd":
        if (e?.target?.value == "") {
          setErrMsg({ ...errMsg, npwdErr: passwordErrorMessages.isRequired });
        } else if (!upperCase.test(e?.target?.value)) {
          setErrMsg({ ...errMsg, npwdErr: passwordErrorMessages.oneUpperCase });
        } else if (!lowerCase.test(e?.target?.value)) {
          setErrMsg({ ...errMsg, npwdErr: passwordErrorMessages.oneLowerCase });
        } else if (!oneDigitRegex.test(e?.target?.value)) {
          setErrMsg({ ...errMsg, npwdErr: passwordErrorMessages.oneDigit });
        } else if (!oneSpclChar.test(e?.target?.value)) {
          setErrMsg({ ...errMsg, npwdErr: passwordErrorMessages.oneSpecial });
        } else if (e?.target?.value.length < 8) {
          setErrMsg({ ...errMsg, npwdErr: passwordErrorMessages.minLength });
        } else if (e?.target?.value.length > 16) {
          setErrMsg({ ...errMsg, npwdErr: passwordErrorMessages.maxLength });
        } else {
          setErrMsg({ ...errMsg, npwdErr: "" });
        }
        setLgnDetails({ ...lgnDetails, npwd: e?.target?.value });
        break;
      case "cnpwd":
        if (e?.target?.value == "") {
          setErrMsg({ ...errMsg, cnpwdErr: passwordErrorMessages.confirmPassword });
        } else if (lgnDetails?.npwd != e?.target?.value) {
          setErrMsg({ ...errMsg, cnpwdErr: passwordErrorMessages.notMatch });
        } else {
          setErrMsg({ ...errMsg, cnpwdErr: "" });
        }
        setLgnDetails({ ...lgnDetails, cnpwd: e?.target?.value });
        break;
      default:
        setErrMsg({ cntryCodeErr: "", mnoErr: "", pwdErr: "", npwdErr: "", cnpwdErr: "" });
        break;
    }
  };

  const handleMobileNoVerification = () => {
    if (lgnDetails?.mno == "") {
      setWarning("Mobile No. is required.");
    } else if (lgnDetails?.mno.length < 4) {
      setWarning("Minimum length of mobile number should be 4.");
    } else {
      getAPICall(Url.mobileNoVerification.replace("{countrycodeId}", lgnDetails?.cntryCode).replace("{mobile_number}", lgnDetails?.mno))
        .then((res) => {
          if (res?.SUCCESS == true && res?.DATA == true) {
            setScreen(2);
          } else if (res?.SUCCESS == true && res?.DATA == false) {
            setScreen(3);
            setForgotPassFlow(false)
            // } else if (res?.SUCCESS == false && (res?.DATA == "ROLE_RECRUTER" || res?.DATA == "ROLE_MANAGER" || res?.DATA == "ROLE_ADMIN")) {
            //   setWarning("Only applicants can access this web application.")
          }
          else if (res?.SUCCESS == false && !!res?.DATA && (PortalUserRole != res?.DATA)) {
            const result = getBaseURLTonavigatetoLogin(LoginAutonavigateEnv, res?.DATA)
            if (result) {
              window.open(result + "#/login?CCId=" + lgnDetails?.cntryCode + "&mobilenumber=" + lgnDetails?.mno, "_self")
            }
          } else {
            setWarning(res?.message);
          }
        }).catch(e=>console.log(e))
    }
  };

  const handlePosterLogin = () => {
    if (lgnDetails?.mno == "") {
      setWarning("Mobile No. is required.");
    } else if (lgnDetails?.mno.length < 4) {
      setWarning("Minimum length should be 4.");
    } else if (lgnDetails?.pwd == "") {
      setWarning("Password is required.");
    } else if (!upperCase.test(lgnDetails?.pwd)) {
      setWarning("Password should contain at least one upper case letter.");
    } else if (!lowerCase.test(lgnDetails?.pwd)) {
      setWarning("Password should contain at least one lower case letter.");
    } else if (!oneDigitRegex.test(lgnDetails?.pwd)) {
      setWarning("Password should contain at least one digit.");
    } else if (!oneSpclChar.test(lgnDetails?.pwd)) {
      setWarning("Password should contain at least one special character.");
    } else if (lgnDetails?.pwd.length < 8) {
      setWarning("Minimum length of Password should be 8.");
    } else if (lgnDetails?.pwd.length > 16) {
      setWarning("Maximum length of Password should be 16.");
    } else {
        let obj = {
          countryCodeId: lgnDetails?.cntryCode,
          username: lgnDetails?.mno,
          password: SHA256(lgnDetails?.pwd).toString(),
          role: "APPLICANT",
        };
        postAPICall(Url?.UserLogin, obj)
          .then((ress) => {
            if (ress?.SUCCESS) {
              setLgnDetails({
                cntryCode: "249",
                mno: "",
                pwd: "",
                npwd: "",
                cnpwd: "",
              });
              let token = ress?.token;
              sessionStorage.setItem("token", token);
              getUserDataname();
            } else {
              setWarning(ress?.message);
            }
          }).catch(e=>console.log(e))
    }
  };

  const handleOtpSend = () => {
    if (lgnDetails?.mno == "") {
      setWarning("Mobile No. is required.");
    } else if (lgnDetails?.mno.length < 4) {
      setWarning("Minimum length of mobile number should be 4.");
    } else if (lgnDetails?.npwd == "") {
      setWarning("New Password is required.");
    } else if (!upperCase.test(lgnDetails?.npwd)) {
      setWarning("Password should contain at least one upper case letter.");
    } else if (!lowerCase.test(lgnDetails?.npwd)) {
      setWarning("Password should contain at least one lower case letter.");
    } else if (!oneDigitRegex.test(lgnDetails?.npwd)) {
      setWarning("Password should contain at least one digit.");
    } else if (!oneSpclChar.test(lgnDetails?.npwd)) {
      setWarning("Password should contain at least one special character.");
    } else if (lgnDetails?.npwd.length < 8) {
      setWarning("Minimum length of Password should be 8.");
    } else if (lgnDetails?.npwd.length > 16) {
      setWarning("Maximum length of Password should be 16.");
    } else if (lgnDetails?.cnpwd == "") {
      setWarning("Confirm password is required!");
    } else if (lgnDetails?.cnpwd != lgnDetails?.npwd) {
      setWarning("Password doesn't match.");
    } else {
        let obj = {
          web: true,
          countryCodeId: lgnDetails?.cntryCode,
          toNumber: lgnDetails?.mno,
          messageType: "OTP_SMS",
          registerd: true,
        };
        postAPICall(Url.SendOtp, obj)
          .then((res) => {
            if (forgotPassFlow == true) {
              setForgotPwd(true)
            } else {
              setForgotPwd(false)
            }
            if (res?.SUCCESS == true) {
              setScreen(4);
              setMsgAuditId(res?.DATA);
            } else {
              setWarning(res?.message);
            }
          }).catch(e=>console.log(e))
    }
  };

  const handleSetPwd = () => {
      if (otp2 == "") {
        setWarning("Please enter OTP.");
      } else if (otp2?.length < 4) {
        setWarning("Invalid OTP.");
      } else {
        let obj = {
          messageAuditId: msgAuditId,
          messageOTP: otp2,
          countryCode: {
            countryCodeId: lgnDetails?.cntryCode,
          },
          mobileNumber: lgnDetails?.mno,
          password: SHA256(lgnDetails?.npwd).toString(),
        };
        patchAPICall(Url.forgotPwd, obj)
          .then((res) => {
            if (res?.SUCCESS == true) {
              setPopupMsg("Password Set Successfully.");
              setSuccessPopup(true);
              // setLgnDetails({ cntryCode: "249", mno: "", pwd: "", npwd: "", cnpwd: "" })
            } else {
              setWarning(res?.message);
            }
          }).catch(e=>console.log(e))
      }
  };
  const handleForgotPwd = () => {
    setForgotPwd(true);
    setScreen(3);
    setForgotPassFlow(true)
  };
  const handlePwdShow = () => {
    setHidePwd({ ...hidePwd, pwd: !hidePwd?.pwd });
  };
  const handleNpwdShow = () => {
    setHidePwd({ ...hidePwd, npwd: !hidePwd.npwd });
  };
  const handleCnpwdShow = () => {
    setHidePwd({ ...hidePwd, cnpwd: !hidePwd.cnpwd });
  };
  const handleAutoLogin = () => {
      setSuccessPopup(false);
      let obj = {
        countryCodeId: lgnDetails?.cntryCode,
        username: lgnDetails?.mno,
        password: SHA256(lgnDetails?.npwd).toString(),
        role: "POSTER",
      };
      postAPICall(Url?.UserLogin, obj)
        .then((ress) => {
          if (ress?.SUCCESS) {
            let token = ress?.token;
            sessionStorage.setItem("token", token);
            getUserDataname();
            setLgnDetails({
              cntryCode: "249",
              mno: "",
              pwd: "",
              npwd: "",
              cnpwd: "",
            });
          } else {
            setWarning(ress?.message);
          }
        }).catch(e=>console.log(e))
  };

  const getUserDataname = () => {
    getAPICall(Url.getuser)
      .then((res) => {
        sessionStorage.setItem("userName", JSON.stringify(res?.DATA?.fullName));
        sessionStorage.setItem("user", JSON.stringify(res?.DATA));

        navigate("/Main/latestJob");
        if (sessionStorage.getItem('jobId')) {
          navigate(`/Main/jobDetails/${sessionStorage.getItem('jobId')}`);
          sessionStorage.removeItem('jobId')
        }
        if (screen == 4) {
          // navigate(`/Main/userProfile`, { state: { headerHide: true } });
        }
      }).catch(e=>console.log(e))
  };

  const handleKeyPressed = (e, field) => {
    if (field == "login") {
      if (e?.key.toLowerCase() == "enter") {
        handlePosterLogin();
      }
    } else if (field == "mobileNoVerification" && screen == 1) {
      if (e?.key?.toLowerCase() == "enter") {
        handleMobileNoVerification();
      }
    }
  };

  const handleResend = () => {
      let obj = {
        web: true,
        countryCodeId: lgnDetails?.cntryCode,
        toNumber: lgnDetails?.mno,
        messageType: "OTP_SMS",
        registerd: true,
      };
      postAPICall(Url.SendOtp, obj)
        .then((res) => {
          setOtp2('')
          setForgotPwd(false);
          if (res?.SUCCESS == true) {
            // setScreen(4)
            setMsgAuditId(res?.DATA);
            setAlert("OTP sent successfully.");
          } else {
            setWarning(res?.message);
          }
        }).catch(e=>console.log(e))
  };

  return (
    <>
      <div className="lgnOuterDiv">
        <div className="lgnFormDiv">
          <div className="lgnLogoDiv">
            <img src={logoImageUrl} alt="webLogo" onClick={() => setScreen(1)} />
          </div>
          <div className="loginH1">
            <h1>Log in to your Account</h1>
          </div>
          {screen != 4 && (
            <div className="loginH1">
              <span
                style={{
                  color:
                    forgotPwd == true && screen == 3
                      ? "#403F3F"
                      : screen == 3
                        ? "#BC0000"
                        : "#403F3F",
                }}
              >
                {forgotPwd == true && screen == 3
                  ? "Reset your password"
                  : screen == 3
                    ? "Your password is not set please set the password"
                    : "Please login to website!"}
              </span>
            </div>
          )}
          {screen != 4 && (
            <div className="RegFieldDiv lgnMtop">
              <label>
                Mobile No
                <span style={{ color: "#BC0000", fontWeight: 400 }}>*</span>
              </label>
              <div className="RegMnoDiv">
                <select disabled
                  value={lgnDetails?.cntryCode}
                  onChange={(e) => handleLgnInputChange(e, "cntryCode")}
                >
                  {countryCodes.map((item) => <option value={item?.countryCodeId}> +{item?.countryCode} </option>)}
                </select>
                <input
                  maxLength={14}
                  inputmode="numeric"
                  value={lgnDetails?.mno}
                  onKeyDown={(e) => handleKeyPressed(e, "mobileNoVerification")}
                  onChange={(e) => handleLgnInputChange(e, "mno")}
                  placeholder="Enter your mobile number"
                />
              </div>
              <small className="RegErrMsg">{errMsg?.mnoErr}</small>
            </div>
          )}
          {screen == 1 ? (
            <div className="RegBtnDiv lgnWidth">
              <button onClick={handleMobileNoVerification}>Next</button>
            </div>
          ) : screen == 2 ? (
            <div>
              <div
                className="RegFieldDiv lgnMtop"
                style={{ position: "relative" }}
              >
                <label>
                  Password
                  <span style={{ color: "#BC0000", fontWeight: 400 }}>*</span>
                </label>
                <input
                  maxLength={30}
                  className="RegInput"
                  value={lgnDetails?.pwd}
                  placeholder="Enter your password"
                  onKeyDown={(e) => handleKeyPressed(e, "login")}
                  type={hidePwd?.pwd == true ? "text" : "password"}
                  onChange={(e) => handleLgnInputChange(e, "pwd")}
                />
                <small className="RegErrMsg">{errMsg?.pwdErr}</small>
                <img
                  alt="eyeIcon"
                  className="eyeIcon"
                  onClick={handlePwdShow}
                  style={{ top: errMsg?.pwdErr == "" ? "50%" : "40%" }}
                  src={hidePwd.pwd == true ? eyeIcon : hiddenEyeIcon}
                />
              </div>
              <div className="RegResendOtpDiv fieldWidth">
                <span onClick={handleForgotPwd}>Forgot Password?</span>
              </div>
              <div className="RegBtnDiv lgnWidth" style={{ marginTop: "3%" }}>
                <button onClick={handlePosterLogin}>Login</button>
              </div>
            </div>
          ) : screen == 3 ? (
            <div>
              <div
                className="RegFieldDiv lgnMtop"
                style={{ position: "relative" }}
              >
                <label>
                  Enter New Password
                  <span style={{ color: "#BC0000", fontWeight: 400 }}>*</span>
                </label>
                <input
                  maxLength={30}
                  value={lgnDetails?.npwd}
                  className="RegInput"
                  placeholder="Enter your password"
                  type={hidePwd.npwd == false ? "password" : "text"}
                  onChange={(e) => handleLgnInputChange(e, "npwd")}
                />
                <small className="RegErrMsg">{errMsg?.npwdErr}</small>
                <img
                  className="eyeIcon"
                  alt="passwordEyeIcon"
                  onClick={handleNpwdShow}
                  style={{ top: errMsg?.npwdErr == "" ? "50%" : "40%" }}
                  src={hidePwd.npwd == true ? eyeIcon : hiddenEyeIcon}
                />
              </div>
              <div
                className="RegFieldDiv lgnMtop"
                style={{ position: "relative" }}
              >
                <label>
                  Confirm Password
                  <span style={{ color: "#BC0000", fontWeight: 400 }}>*</span>
                </label>
                <input
                  maxLength={30}
                  value={lgnDetails?.cnpwd}
                  className="RegInput"
                  placeholder="Enter your confirm password"
                  type={hidePwd.cnpwd == false ? "password" : "text"}
                  onChange={(e) => handleLgnInputChange(e, "cnpwd")}
                />
                <small className="RegErrMsg">{errMsg?.cnpwdErr}</small>
                <img
                  className="eyeIcon"
                  alt="confirmEyeIcon"
                  onClick={handleCnpwdShow}
                  style={{ top: errMsg?.cnpwdErr == "" ? "50%" : "40%" }}
                  src={hidePwd.cnpwd == true ? eyeIcon : hiddenEyeIcon}
                />
              </div>
              <div className="RegBtnDiv lgnWidth">
                <button onClick={handleOtpSend}>Next</button>
              </div>
            </div>
          ) : screen == 4 ? (
            <div>
              <div className="lgnOtpImgDiv">
                <img src={mobileImg} />
              </div>
              <div className="lgnOtpDivTxt">
                We have sent OTP on your registered mobile number
              </div>
              <div className="lgnOtpDiv">
                <label>
                  Please enter the OTP
                  <small style={{ color: "#BC0000" }}>*</small>
                </label>
                <OtpInput
                  value={otp2}
                  onChange={setOtp2}
                  numInputs={4}
                  renderSeparator={<span style={{ width: "1.5vw" }}></span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle="regOTPinputField"
                  inputType="number"
                />
              </div>
              <div className="noOtpTxt">
                Didn't receive a OTP?{" "}
                <span style={{ cursor: "pointer" }} onClick={handleResend}>
                  Resend OTP
                </span>
              </div>
              <div className="setPwdBtnDiv">
                <button className="setPwdBtn" onClick={handleSetPwd}>
                  Set Password
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="LgnNoAcc">
            {/* Don't have an account?&nbsp;
            <span onClick={() => navigate("/register")}>Register</span> */}
          </div>
        </div>
        <div className="lgnImgDiv">
          <div className="OverlayNew">
            <div className="RegImgDivTxt LgnImgDivTxt">
              <div className="HiringMore">Make Campus Recruitment</div>
              <div className="humanTxt">Seamlessly</div>
              <p>
                Welcome to CampusPlacement.in – revolutionizing campus recruitment by seamlessly connecting students and college recruiters through innovative processes and technology. Join us in transforming the way talent meets opportunity, making the campus placement process smooth and efficient for everyone involved.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={successPopup}
        // onRequestClose={() => setSuccessPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p
              onClick={() => {
                navigate("/login");
                setSuccessPopup(false);
                setScreen(2);
              }}
              className="delAccModalCrossPara"
              role='successLoginClose'
            >
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">{popupMsg}</div>
        <div className="okBtnModal">
          <button role='successLogin' onClick={handleAutoLogin}>OK</button>
        </div>
      </Modal>
    </>
  );
};

export default Login;
